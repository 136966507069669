<template>
  <div>
    <v-row class="mt-10">
      <v-col class="text-center" offset-md="4" md="4" >
        <h2>Integração Mercado Pago</h2>
        <v-img class="mt-10 mx-auto" width="200px"
          :src="'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.2/mercadopago/logo__large@2x.png'"
        />
        <div class="my-4">
          Conecte seu PikShop POS ao Mercado Pago e venda por links de pagamento!
        </div>
        <v-btn
          class="text-capitalize"
          color="primary"
          @click="redirectToAuth()"
          v-if="!isLoggedMercadoPago"
          >Conectar Mercado Pago</v-btn
        >
        <v-btn
          class="text-capitalize"
          color="secondary"
          v-if="isLoggedMercadoPago"
          @click="handleDisconectMercadoPago()"
          >Desconectar Mercado Pago</v-btn
        >
      </v-col>
    </v-row>
    <!-- {{ company }} -->
  </div>
</template>

<script>
export default {
  data: () => ({
    app_id: 1424125984984464,
    redirect_uri: "http://localhost:8080/configuracoes/integracao_mercado_pago",
  }),

  computed: {
    company() {
      return this.$store.state.auth.user.company;
    },

    isLoggedMercadoPago() {
      return this.company.mercado_pago_access_token;
    },
  },

  mounted() {
    if (this.$route.query.code) {
      this.postAuth(this.$route.query.code);
    }
  },

  methods: {
    redirectToAuth() {
      let url =
        "https://auth.mercadopago.com.br/authorization" +
        "?client_id=" +
        this.app_id +
        "&response_type=code" +
        "&platform_id=mp" +
        "&redirect_uri=" +
        this.redirect_uri;
      console.log(url);
      window.location.href = url;
    },

    async postAuth(code) {
      console.log(code);

      let params = {
        authorization_code: code,
      };

      await this.$http
        .$post("mercado-pago/auth", params)
        .then(async (response) => {
          await this.$auth.getLoggedUser();
        });
    },

    async handleDisconectMercadoPago() {
      await this.$http.$post("mercado-pago/logout").then(async (response) => {
        await this.$auth.getLoggedUser();
      });
    },
  },
};
</script>

<style>
</style>